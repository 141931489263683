import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Image from 'components/image';

import Button from 'components/Button';
import API from 'utils/api';
import { cookies, PlayStoreLink } from 'utils/constants';
import { destroyCookie } from 'nookies';
import config from 'utils/config';
import { AppContext } from 'pages/_app';
import { setCompanyData } from 'stores/actions';

import OnboardLogo from 'public/images/landing/logo.svg';
import OnboardLogoWhite from 'public/images/landing/logo-white.svg';

export const defaultMenus: Array<{
  link?: string;
  title: string | ReactElement | ((T: boolean) => void);
}> = [
  {
    title: 'About Us',
    link: '#about',
  },
  {
    title: 'For Seafarer',
    link: '#seafarer',
  },
  {
    title: 'For Companies',
    link: '#company',
  },
  // {
  //   title: 'Blog',
  //   link: '/blog',
  // },
];

type NavbarLandingProps = {
  hasLoginToken?: boolean /* hasLoginToken is true if user has login token in cookies */;
};

export default function NavbarLanding({ hasLoginToken }: NavbarLandingProps) {
  const router = useRouter();
  const { dispatch } = useContext(AppContext);
  const [scrolled, setScrolled] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [loggedInState, setLoggedInState] = useState<boolean>(null);

  function scrollListener() {
    const isScrolled = window.scrollY > (10 / 100) * document.body.clientHeight;
    setScrolled(isScrolled);
  }

  const text = useMemo(() => {
    switch (loggedInState) {
      case true:
        return 'Go to Dashboard';
      case false:
        return 'Company Sign In';
      default:
        return 'Loading...';
    }
  }, [hasLoginToken, loggedInState]);

  const routes = useMemo(() => {
    switch (loggedInState) {
      case true:
        return '/dashboard';
      case false:
        return '/login';
      default:
        return '#';
    }
  }, [hasLoginToken, loggedInState]);

  const getTitleMobileMenu = useCallback((menu) => {
    if (!menu.title) return;
    if (typeof menu.title === 'function') {
      return menu.title();
    }

    return (
      <Link href={menu.link || '#'} passHref>
        <a className="text-primary">{menu.title}</a>
      </Link>
    );
  }, []);

  const getTitleWebMenu = useCallback(
    (menu) => {
      if (!menu.title) return;
      if (typeof menu.title === 'function') {
        return menu.title(scrolled);
      }

      return (
        <Link href={menu.link || '#'} passHref>
          <a className={scrolled ? 'text-primary' : 'text-white'}>
            {menu.title}
          </a>
        </Link>
      );
    },
    [scrolled]
  );

  const menus = useMemo(() => {
    const tempMenus = [...defaultMenus];

    if (!tempMenus) return null;

    if (router.pathname === '/crew') {
      tempMenus.push({
        title: (scrolled) => (
          <>
            <Button
              outline
              text={
                <>
                  Get <span className="font-inter">OnBoard</span>
                </>
              }
              size="small"
              className={`hidden ipad:block rounded-lg ${
                scrolled
                  ? 'bg-primary hover:bg-transparent text-white hover:text-primary border-transparent hover:border-primary'
                  : 'bg-transparent hover:bg-white text-white hover:text-primary border-white hover:border-transparent'
              }`}
              onClick={() => router.push(PlayStoreLink)}
            />
            <Link href={PlayStoreLink} passHref>
              <a className="text-primary ipad:hidden">
                Get <span className="font-inter font-bold">OnBoard</span>
              </a>
            </Link>
          </>
        ),
      });
    } else if (router.pathname === '/' || router.pathname === '/company') {
      tempMenus.push({
        title: (scrolled) => (
          <>
            <Button
              outline
              text={text}
              size="small"
              className={`hidden ipad:block rounded-lg ${
                scrolled
                  ? 'bg-primary hover:bg-transparent text-white hover:text-primary border-transparent hover:border-primary'
                  : 'bg-transparent hover:bg-white text-white hover:text-primary border-white hover:border-transparent'
              }`}
              onClick={() => router.push(routes)}
            />
            <Link href={routes} passHref>
              <a className="text-primary ipad:hidden">{text}</a>
            </Link>
          </>
        ),
      });
    }

    return tempMenus;
  }, [text, routes, loggedInState, hasLoginToken]);

  useEffect(() => {
    document.addEventListener('scroll', scrollListener);

    return () => {
      document.removeEventListener('scroll', scrollListener);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = sidebar ? 'hidden' : 'auto';
    if (sidebar) setScrolled(sidebar);
    else setScrolled(window.scrollY > (10 / 100) * document.body.clientHeight);
  }, [sidebar]);

  useEffect(() => {
    setSidebar(false);
  }, [router.asPath]);

  useEffect(() => {
    if (hasLoginToken) {
      API.companyGetCompanyDetails({
        withCredentials: true,
      })
        .then((res) => {
          if (!res.data.isSuccess) {
            destroyCookie({}, cookies.token, { ...config.cookiesConfig });
          } else {
            dispatch(setCompanyData(res.data.data));
          }
          setLoggedInState(res.data.isSuccess);
        })
        .catch(() => {
          destroyCookie({}, cookies.token, { ...config.cookiesConfig });
          setLoggedInState(false);
        });
    } else {
      setLoggedInState(false);
    }
  }, [hasLoginToken]);

  return (
    <>
      <nav
        className={`fixed top-0 z-30 w-full inset-x-0 transition-all ${
          scrolled ? 'bg-white shadow-md h-64' : 'bg-primary h-48'
        }`}
      >
        <div className="px-16 ipad:px-96 mx-auto flex items-center justify-between h-full">
          <Link href="/" passHref>
            <a>
              <Image
                src={scrolled ? OnboardLogo : OnboardLogoWhite}
                width={130}
                alt="logo"
              />
            </a>
          </Link>
          {menus && (
            <>
              <ul className="gap-x-32 px-16 text-white hidden ipad:flex items-center">
                {menus.map((menu, index) => (
                  <li key={index}>{getTitleWebMenu(menu)}</li>
                ))}
              </ul>
              <a
                onClick={() => setSidebar(!sidebar)}
                className={`text-large1 ${
                  scrolled ? 'text-primary' : 'text-white'
                } rounded-md flex items-center justify-center ipad:hidden w-32 h-32`}
              >
                <i className="ri-menu-line" />
              </a>
            </>
          )}
        </div>
      </nav>
      {menus && (
        <div
          className={`ipad:hidden fixed inset-0 w-full h-screen shadow-md z-20 bg-white pt-72 transition-transform left-0 transform desktop:translate-x-0 ${
            sidebar ? 'translate-x-0' : '-translate-x-full'
          }`}
        >
          <ul className="gap-x-32 px-16 text-white">
            {menus.map((menu, index) => (
              <li className="p-16 px-0" key={index}>
                {getTitleMobileMenu(menu)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}
