import ModalPrivacyPolicy from 'components/modal/ModalPrivacyPolicy';
import dayjs from 'dayjs';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { contact_email } from 'utils/constants';

export default function Footer() {
  const [privacyModal, setPrivacyModal] = useState(false);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window?.location?.hash === '#privacy'
    ) {
      setPrivacyModal(true);
    }
  }, []);

  return (
    <>
      <ModalPrivacyPolicy
        show={privacyModal}
        onHide={() => setPrivacyModal(false)}
      />
      <footer className="flex flex-col ipad:flex-row gap-16 w-full items-center justify-center bg-primary border-t border-white py-24 ipad:py-16 px-16 ipad:px-96">
        <div className="flex justify-around w-full ipad:justify-start ipad:gap-20 ipad:w-1/3">
          <Link href="privacy-policy" passHref>
            <a className="text-white font-semibold underline">Privacy Policy</a>
          </Link>
          <Link href="terms-and-conditions" passHref>
            <a className="text-white font-semibold underline">
              Terms &amp; Conditions
            </a>
          </Link>
        </div>

        <div className="ipad:w-1/3">
          <p className="text-white text-center">
            Contact Us at {` `}
            <a href={`mailto:${contact_email}`} className="text-white inline">
              {contact_email}
            </a>
          </p>
        </div>

        <div className="ipad:w-1/3">
          <p className="text-white text-center ipad:text-right">
            &copy; {dayjs().year()} OnBoard. All Rights Reserved.
          </p>
        </div>
      </footer>
    </>
  );
}
